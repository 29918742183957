
/* Default font for the entire application */
body, html {
    font-family: 'Open Sans', sans-serif;
}

/*
 Landing Page
*/
.pt--10 { padding-top: 10px; }
.pl--20 { padding-left: 20px !important; }
.pl--40 { padding-left: 40px !important; }
.pr--20 { padding-right: 20px; }
.pt--20 { padding-top: 20px; }
.pb--20 { padding-bottom: 20px !important; }
.mt--30 { margin-top: 30px !important; }
.mt--10 { margin-top: 10px !important; }
.ptb--6 { padding-top: 6px !important; padding-bottom: 6px !important;}
.mb--15 { margin-bottom: 15px !important; }
.pl--85 { padding-left: 85px; }
.ml--5 { margin-left: 5px !important; }
.ml--30 { margin-left: 30px !important; }
.mr--0 { margin-right: 0 !important; }
.w-70 { width: 70% }
.w-100 { width: 100% }
.w-native { width: 120px; }
.max-width-700 { max-width: 700px; }
.clear { clear: both; }
.text-yan { color: #333 !important; }
.text-desc { color: #8898AA; }
.border--r { border-right: 1px solid #DCDCDC; }
.border--l { border-left: 1px solid #DCDCDC; }
.border--t { border-top: 1px solid #DCDCDC; }
.border--b { border-bottom: 1px solid #DCDCDC; padding-bottom:1rem; }
.text-sidebar { color:#6A6C89 !important; }
.line-height-1 { line-height: 1.1; }
.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.flex-start {
    justify-content: flex-start;
    margin-bottom: 3px;
}
.flex-end {
    justify-content: flex-end;
}
.flex-between {
    justify-content: space-between;
}
.align-item-start {
    align-items: flex-start !important;
}
.align-top {
    align-items: flex-start;
}
strong {font-weight: bold;}
.mb--0 { margin-bottom: 0;}
.mb--10 { margin-bottom: 10px !important; }
.pb--0 { padding-bottom: 0;}
body {
    overflow-x: hidden;
    background: white;
}
body, p {
    font-size: 15px;
}
pre, code, kbd, samp {
    font-family: 'OPEN SANS', sans-serif;
}
.about--title {
    padding-top: 30px;
    padding-bottom: 0;
    width: 230px;
    border-bottom: 1px solid #DCDCDC;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    font-size: 25px;
    font-weight: 500;
    color: #172B4D;

    p {
        color: black;
        font-size: 12px;
    }
}
/* .landing-header {
    background: #020738;
    text-align: center;
    padding-bottom: 1rem;

    img {
        width: 209px;
        margin: 2rem auto;
    }
} */
.landing--bg {
    background: url('../../img/theme/header_bg.jpg') no-repeat center top;
    width: 100%;
    min-height: 720px;
    background-size: cover;
    text-align: left;
    height: calc(100vh - 60px);
    position: relative;

    .container {
        padding-left: 5%;
    }
    .header__more {
        position: absolute;
        bottom: 80px;
        width: 200px;
        left: calc(50% - 100px);
        text-align: center;
        p {
            font-size: 15px;
            color: white;
            font-weight: 500;
        }
        img {
            width: 34px;
            height: auto;
        }
    }
}

.header {
    .header__title {
        font-size: 40px;
        font-family: 'OPEN SANS', sans-serif;
        font-weight: normal;
        color: white;
        text-align: left;
        padding-top: 14%;
        padding-bottom: 0;
        margin-bottom: 0;
        line-height: 66px;
    }
    .header__subtitle {
        font-size: 23px;
        font-family: 'OPEN SANS', sans-serif;
        font-weight: 100;
        color: white;
        text-align: left;
        margin-top: 30px;
        line-height: 40px;
    }
    strong {
        font-weight: bold;
    }
    .header__button {
        background: #714E6C;
        color: white;
        font-family: 'OPEN SANS', sans-serif;
        font-size: 13px;
        font-weight: 400;
        padding: 10px 25px;
        border-radius: 20px;
        margin-top: 20px;
        border: none;
    }
}

.landing--section {
    margin: 0 auto;

    &.gray_white {
        background: white;
    }
    .card {
        text-align: center;
        box-shadow: 10px 10px 12px rgba(0,0,0,0.18);
        .card-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
            h6 {
                margin-top: 60px;
            }
        }
        img {
            position: absolute;
            top: -50px;
        }
    }
    .landing--btn {
        background: #707070;
        padding: 0.5rem 2rem;
        color: white;
    }
    .nav-link {
        display: inline-block;
    }
    .card-body {
        min-height: 300px;
        border: 1px solid #BEBEBE;
        p {
            color: #8A8A8A;
            font-size: 1rem;
            width: 90%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
    h6 {
        color: #050A30;
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .landing__graph {
        text-align: center;
        .col-xl-8 {
            margin: 0 auto;
        }
        img {
            width: 100%;
        }
        p {
            text-align: left;
            font-size: 1rem;
            line-height: 1.4rem;
            color: #8A8A8A;
            font-weight: 400;
            margin-bottom: 1.5rem;
        }
        h5 {
            font-size: 20px;
            color: #8A8A8A;
            font-weight: bold;
            text-align: left;
            margin-bottom: 0;
        }
    }
}

.nav-title {
    font-size: 20px;
    color: white;
    margin: 0;
    margin-right: 20px;
    padding: 0;
    font-weight: 100;
}

.btn-trial {
    background: #714E6C;
    border-radius: 20px;
    padding: 8px 25px;
    &:hover {
        background: #714E6C;
        transform: unset;
        opacity: 0.9;
    }
}

.bg-default {
    background: white !important;
}

#footer-main {
    height: auto;
    z-index: 999;
    position: relative;
    background: none;
    .copyright {
        color: #8A8A8A !important;
    }
    a {
        color: #8A8A8A !important;
    }
}

/* Home Page */
.dashboard--logo, .navbar-brand {
    height: 90px;
    img {
        height: 100% !important;
    }
}
.sidenav {
    top: 60px !important;
    transform: unset !important;
}
#navbar-main, .navbar-top {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99;
    height: 60px;
    background-color: #050A30;
}
#navbar-main {
    background: none;
    padding: 0;
    height: 80px;
    width: auto;
    position: static;
    .navbar-brand img {
        height: auto;
    }
    .nav-link {
        color: #8A8A8A;
    }
    .container {
        width: 93%;
        max-width: unset;
        padding-right: 0;
    }
}
.main-content {
    position: relative;
    margin-top: 0;
    min-height: calc(100vh - 120px);
}
.signout-nav {
    position: absolute;
    top: 20px;
    right: 40px;
}
.auth-content {
    margin-top: 0;
}
.landing-content {
    margin-top: -80px;
}
.g-sidenav-pinned {
    .sidenav ~ .main-content {
        margin-left: 250px;
    }
    .sidenav-header .ml-auto {
        margin-left: 10px !important;
    }
}
.g-sidenav-hidden {
    .sidenav ~ .main-content {
        margin-left: 65px;
    }
}
.navbar-vertical.navbar-dark {
    // background-color: #050a30;
    background: white;
    box-shadow: unset;
}
.navbar-vertical.navbar-expand-xs.fixed-left {
    border-color: #b2b2b2;
    overflow: hidden;
}
.navbar-vertical.navbar-expand-xs {
    max-width: 65px;
    bottom: 100px;
    &:hover {
        max-width: 250px;
        .ml-auto {
            margin-left: 10px !important;
        }
    }
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item {
    margin-left: 10px;
    margin-right: 10px;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
    padding: 0.5rem 1rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item.active > .nav-link, .navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item:hover > .nav-link {
    // background: #707070;
    border-radius: 5px;
}
.navbar-vertical .navbar-nav .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
    color: white;
}
.nav-link-text { color: #6A6C89; }
.active .nav-link-text { color: #333; }
.sidenav-toggler {
    padding: 10px !important;
}
.sidenav-toggler:hover {
    // background: #707070;
    border-radius: 5px;
}
.sidenav-toggler-line {
    color: #6A6C89;
    background: #6A6C89;
}
.dashboard--logo {
    margin-left: 10%;
}

.dashboard--footer {
    position: relative;
    z-index: 9999;
    padding: 0;
    background: #050A30;
    margin: 0;
    max-width: unset !important;
    .footer {
        background: none;
        padding: 20px 0;
    }
    .copyright {
        color: white !important;
    }
    .footer .nav .nav-item .nav-link, .footer .footer-link, a {
        color: white !important;
    }
}

/* Homepage Style */
.home-wrap {
    h2 {
        font-weight: bold;
    }
    .btn-info {
        padding: 7px 10px;
    }
}
.min-height300 {
    .col-xl-4 {
        min-height: 300px;
    }
    p {
        width: 85% !important;
    }
}
.min-height330 {
    .col-xl-4 {
        min-height: 330px;
    }
    p {
        width: 90% !important;
    }
}

.custom-checkbox .custom-control-input ~ .custom-control-label {
    height: auto;
}

/* Auth Page Style */
.full_wrap {
    /* background: url('../../img/theme/header_bg.jpg') no-repeat center top; */
    width: 100vw;
    min-height: 600px;
    background-size: cover;
    height: calc(100vh - 180px);
}
.auth__row {
    margin: 0;
}
.auth__row > div {
    margin-left: auto;
    margin-right: auto;
}
.auth_wrap {
    width: 50%;
    margin: 0 auto;
    // margin-top: 7%;
    border: 1px solid #cbcbcb;
    background: #fcfbfb;
    box-shadow: rgba(0, 0, 0, 0.3) 3px 6px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    border-radius: 1px;

    h3 {
        font-size: 23px;
        font-weight: normal;
        color: #172B4D;
        display: inline-block;
        padding-right: 25px;
        padding-bottom: 5px;
        border-bottom: 1px solid #BBB;
    }
    .auth--txt {
        font-size: 15px;
        color: #172B4D;
    }
    .auth--desc {
        font-size: 12px;
        color: #8898AA;
    }
    .input-group {
        box-shadow: unset;
        border: 1px solid #714E6C;
    }
}
.btn-info {
    background: #714E6C;
    border: 1px solid #714E6C;
    padding: 7px 30px;
    border-radius: 8px;
    &:hover {
        background: #714E6C;
        border: 1px solid #714E6C;
    }
}
.btn-remove {
    background: #0da5c0;
    border: 1px solid #11cdef;
    border-radius: 8px;
    color: white;
    &:hover {
        background: #714E6C;
        border: 1px solid #714E6C;
        color: white;
    }
}
.datasource-wrap {
    .btn-remove {
        padding: 2px 1px !important;
    }
}
.dataset-input {
    align-items: center;

    button {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    input {
        padding: 7px 15px;
    }
}
.auth_wrap.rect--width {
    width: 65%;
}
.text-light {
    cursor: pointer;
}
.auth--link {
    cursor: pointer;
    font-size: 15px;
    color: #0014FF;
    display: inline-block;
    margin-left: 6px;
    text-decoration: underline;
}

.auth_wrap, .onboarding--wrap {
    .btn-info {
        background: #6A6C89;
        border: 1px solid #6A6C89;
        padding: 3px 30px;
    }
}

.authextra {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: -1;
    background: #050A30;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .authextra__wrap {
        width: 70%;
        text-align: left;
        color: white;
        padding-bottom: 10%;
    }
    .authextra__logo {
        text-align: center;
    }
    h4 {
        color: white;
        font-size: 23px;
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 0;
    }
    p {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 17px;
    }
    .authextra__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
        font-size: 15px;
        img {
            width: 46px;
            height: auto;
            margin-right: 20px;
        }
    }
}
.register--extra {
    width: 33%;
    right: 0;
}

.code--extra {
    width: 41.6%;
    left: 0;
    img {
        width: 100%;
        height: auto;
    }
    .authextra__wrap {
        padding-bottom: 0;
    }
}
.code--row {
    justify-content: flex-end;
    .btn-info {
        background: #714E6C;
        border: 1px solid #714E6C;
        padding: 7px 30px;
        border-radius: 8px;
    }
    .auth_wrap {
        margin-top: 10%;
    }
}

.sign--bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    img {
        width: auto;
        height: 100%;
    }
}
.signin--wrap {
    // margin-top: 15%;
    width: 100%;
    .card-body {
        padding-top: 60px;
        position: relative;
        /* background: url('../../img/brand/logo_2.jpg') no-repeat 91% 30px; */
        background-size: 30%;
    }
    h1 {
        text-align: center;
        font-size: 3rem;
        color: #8A8A8A;
        transition: transform 2s ease-in-out;
        transform: translateX(+100vw);
        &:first-child {
            border-bottom: 1px solid #707070;
            transform: translateX(-100vw);
            margin-top: -20px;
        }
    }
}
.welcome--wrap {
    margin-top: 15%;
    width: 100%;
    .card-body {
        padding-top: 1rem;
    }
    h3 {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    img {
        width: auto;
        max-width: 100%;
    }
}
.normal-lh {
    line-height: 1;
}
.onboarding--wrap {
    background: #fcfbfb;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.3) 3px 6px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    span, .auth--link {
        font-size: 14px;
    }
    .card-body {
        padding-top: 15px;
        padding-bottom: 15px;

        h3 {
            border-bottom: 1px solid #BBB;
            display: inline-block;
            padding-bottom: 5px;
            padding-right: 30px;
        }
    }
}

.modal {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    .companyModal {
        width: 80%;
        max-width: unset !important;
        .modal-content {
            border-radius: 28px;
        }
    }
    .credentialModal {
        width: 30%;
        max-width: 518px !important;
        .modal-content {
            border-radius: 28px;
            background: #dfe0e2;
        }
        .input-group {
            background: white;
            border: 1px solid #714e6c;
        }
        .wrap {
            display: block;
            margin-left: 10px;
            margin-right: 20px;
        }
        h5 {
            font-size: 17px;
            line-height: 23px;
            color: #000000;
            font-weight: normal;
            text-align: center;
        }
    }
    .codeContainer {
        height: 250px;
        overflow-y: auto;

        code {
            pre {
                margin-bottom: 0;
            }
        }
    }
    .wrap {
        display: flex;
        justify-content: row;
        justify-content: center;
    }
    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    li {
        border-bottom: 1px solid #CCCCCC;
        &:last-child {
            border-bottom: none;
        }
    }
    .item-list {
        width: 100%;
        border-right: 1px solid #CCCCCC;
        &:last-child {
            border-right: none;
        }
        span {
            text-align: left;
        }
    }
    .btn-select {
        background: none;
        border: none;
        box-shadow: unset;
        font-size: 14px;
        height: 48px;
        padding: 3px 20px;
        align-items: center;
        color: #050A30;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .btn-inner--text {
            width: 70%;
        }
        .btn-inner--icon {
            font-size: 25px;
        }
        &:hover, &.selected {
            transform: translateY(0);
            background: #a0c2f9;
        }
    }
    .item-list:first-child .btn-select {
        padding-left: 0;
    }
}
.modal .btn-select:hover, 
.modal .btn-select.selected {
    background: #b4b4c2;
    border-radius: unset;
}

.dashboardContainer {
    height: calc(100vh - 120px);
}
.dropdown {
    cursor: pointer;
}
.modal-backdrop.show {
    opacity: 0.6;
}
.right--border {
    border-right: 1px solid #DCDCDC;
}
section.profile {
    padding: 40px;
    color: #172B4D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .dark-border {
        border: 1px solid #CCCCCC;
    }
    .profile-nav {
        width: 24%;
        padding-top: 0;
        ul {
            display: block;
            min-height: 90vh;
            border-right: 1px solid #DCDCDC;
            padding-top: 20px;
        }
        li a.nav-link {
            color: #172B4D;
            display: block;
            padding: 10px 5px;
            &:hover, &.active {
                background: #dbd8d8;
            }
        }
    }
    .profile-wrap {
        width: 100%;
        padding-top: 20px;
        padding-left: 15px;
        ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        h2 {
            font-size: 25px;
            font-weight: bold;
            padding-bottom: 15px;
            border-bottom: 1px solid #DCDCDC;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 25px;
            font-weight: 500;
        }
        .custom-control-label {
            height: auto;
        }
        .instruction-block {
            padding-right: 50px;
        }
    }
    .edit-profile-item {
        background: white;
        padding: 10px 20px 10px 5px;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title-section {
            width: 40%;
            i {
                margin-right: 15px;
            }
        }
        .input-section {
            width: 35%;
        }
        .btn-section {
            width: 20%;
            text-align: right;
        }
        .form-control {
            padding: 5px 10px;
            height: auto;
        }
        .enable-section, .credential-section {
            width: 30%;
            text-align: left;
        }
    }
    .datasource-item {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .enable-option {
            width: 50px;
        }
        .title-section {
            width: 45%;
        }
        .enable-section {
            width: 35%;
        }
    }
} 
section.optimizer {
    padding: 40px 20px;
    padding-top: 15px;

    .rdt.rdtOpen .rdtPicker {
        left: 0 !important;
    }

    .pd-10 {
        padding-left: 9px;
        padding-right: 9px;
    }
    .pb-0 { padding: 0 }
    .align-center { text-align: center; }
    .align-left { 
        text-align: left;  
        padding-left: 10px; 
    }
    .align-right { 
        padding-right: 10px;
        border-right: 1px solid #BBB;
        text-align: right; 
    }
    .quick-facts {
        margin-bottom: 6px;
        font-size: 13px;
    }
    .optimizer-nav,
    .optimizer-wrap {
        background: #fbf9fa;
        display: flex;
    }
    .optimizer-nav {
        padding-bottom: 0;
        .nav-item {
            background: #ECEEEF;
            margin-right: 3px;
        }
        .nav-link {
            color: #172B4D;
            font-weight: bold;
            padding: 0.2rem 1rem;
            &.active {
                background: #D8C6D6;
            }
        }
    }
    .optimizer-wrap {
        border-radius: 6px;
        position: relative;
        margin-top: 0;
        min-height: calc(100vh - 240px);

        Button { padding: 1px 10px; }
        .flex { display: flex; }
        .border-r { border-right: 1px solid #BBB; }
        .border-l { border-left: 1px solid #BBB; }
        hr {
            margin-top: 6px;
            margin-bottom: 10px;
            width: 120px;
            border-top: 1px solid #BBB;
        }
        .order-btn {
            background: #b085ae;
            border: 1px solid #b085ae;
            border-radius: 16px;
            color: #fbf9fa;
            width: 100%;
        }
        .select-wrap {
            text-align: left;
            position: relative;
            border: 1px solid #bbbabb;
            padding: 10px;
            padding-top: 15px;
            margin-top: 20px;
            & > p {
                position: absolute;
                top: -13px;
                left: 25px;
                background: #fbf9fa;
                padding: 0 7px;
            }
            .cal-desc {
                position: relative;
                left: 0;
                top: -7px;
            }
            .section-no {
                position: absolute;
                width: 24px;
                height: 24px;
                top: -10px;
                left: -10px;
                background: white;
                border-radius: 50%;
                cursor: pointer;
            }
            .tooltip-no {
                position: absolute;
                top: -30px;
                left: 15px;
                width: 300px;
                background: white;
                border: 0.5px solid #714E6C;
                border-radius: 5px;
                padding: 10px 20px;
                color: #714E6C;
                visibility: hidden;
                text-align: justify;
                transition: all 0.5s ease-in-out;
                z-index: 999;
                box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.2);
            }
            .section-no:hover ~ .tooltip-no {
                visibility: visible;
                transition: all 0.5s ease-in-out;
            }
            .section-no span {
                display: flex;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;
                background: #b085ad;
                border: 1px solid #bbbaba;
                border-radius: 50%;
                color: white;
                font-size: 12px;
                font-weight: bold;
                font-family: 'Arial';
            }
            ul {
                justify-content: flex-start;
            }
            .nav-link {
                color: #172B4D;
                text-align: center;
                padding: 0.15rem;
                &.active {
                    background: #D8C6D6;
                }
            }
            .nav-item {
                margin-right: 4px;
                width: 30%;
                margin-bottom: 4px;
                background: #eceeef;
            }
            &.two-columns ul {
                justify-content: space-around;
            }
            &.two-columns .nav-item {
                width: 42%;
            }
            .nav-link {
                padding: 0.15rem;
            }
            break {
                flex-basis: 100%;
                width: 0px; 
                height: 0px; 
                overflow: hidden;
            }
            table {
                margin: 0 auto;
                th {
                    border-top: 1px solid #e9ecef;
                    border-bottom: 1px solid #e9ecef;
                    background: #f6f9fc;
                    color: #8898AA;
                    font-size: 11px;
                    padding: 10px;
                }
                td {
                    color: #525F7F;
                    font-size: 13px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #e9ecef;
                }
            }
            .left {
                float: left;
                text-align: left;
            }
            .right {
                float: right;
                text-align: right;
            }
            label {
                font-size: 12px;
                margin-left: 3px;
            }
            .clear { clear: both; }
        }
        .price-wrap {
            background: #B3A2B1;
            border-radius: 5px;
            width: 80%;
            padding: 10px;
            text-align: center;
        }
        .text { 
            width: 105px;
            background: #eceeef;
            border-radius: 5px;
            .form-control {
                padding: 3px 10px;
                height: auto;
                color: #525F7F;
                background: #eceeef;
                text-align: center;
            }
        }
        .w-100 { 
            .form-control {
                background: #D8C6D6; 
                color: #172B4D;
                cursor: pointer;
                border: none;
            }
        }
        .input-group-merge {
            .form-control {
                padding: 3px 6px;
                height: auto;
                color: #525F7F;
                background: white;
                text-align: center;
            }
        }
        .text1 { 
            width: 70px;
            border-radius: 5px;
            margin-right: 3px;
            margin-bottom: 3px;
        }
        .plus-btn, 
        .minus-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            color: white;
            line-height: 10px;
            border: none;
            font-size: 14px;
            margin-left: 3px;
            background: #6A6C89;
        }
        .minus-btn {
            background: red;
        }
        .white-text {
            color: white;
        }
    }
}

/* styles for options optimier - PDF Link */
.pdfLinkWrap {
    background: #fbf9fa;
    .pdfLinkTabs {
        position: relative;
        button {
            width: 130px;
            height: 35px;
            color: white;
            border-radius: 12px;
            line-height: 1;
            margin-right: 5px;
            background: #6a6c89;

            &.btn-select, &:hover {
                background: #b186ae;
            }
        }
        .pdfLinkStructure {
            position: absolute;
            top: 35px;
            left: 15px;
            background: #6A6C89;
            z-index: 99;
            min-width: 850px;
            padding: 15px;
            p { color:white; font-size:14px; text-align:center; }
            .table-responsive { background:white; padding-right:10px; padding-bottom:15px }
            table { width:auto; margin:0 auto; }
            th { color:black; text-align:center; height:30px; line-height:0; padding:0.5rem }
            td { border:1px solid black; text-align:center; padding:0; height:30px; line-height:0; }
            td a { color:#111; text-decoration:underline; display:block; 
                background:#dfdcdb; width:100%; height:30px; line-height:30px; }
            span.arrow { 
                position:absolute; width:14px; height:10px; border-left:10px solid transparent;
                border-right:10px solid transparent; border-bottom:14px solid #6A6C89;
                top:-14px; z-index:100; left:78px;
            }
            span.close { position:absolute; right:5px; top:5px; color:white; 
                font-weight:700; font-size:25px; cursor:pointer; transition:all 0.5s ease-in-out; }
        }
    }
    .pdfLinkHeatMap {
        min-height: 400px;
        // height: 60vh;
        padding-top: 1em;
        text-align: center;
        position: relative;
        z-index: 10;
        .col-md-2, .col-md-10 {
            padding-left: 0;
            padding-right: 0;
        }
        .col-md-2 {
            padding-right: 15px;
            padding-top: 15px;
        }
        h2 {
            color: #32325D;
            font-weight: 300;
            font-size: 22px;
            display: inline-block;
            text-align: center;
            // border-bottom: 1px solid #d0cecf;
        }
        h3 {
            color: #32325D;
            font-weight: 300;
            font-size: 18px;
            display: inline-block;
            // border-bottom: 1px solid #d0cecf;
        }
        .sub-params {
            color: #32325D;
            font-weight: 300;
            font-size: 16px;
            display: inline-block;
            text-align: left;
        }
        .risk-tabs {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .tab-btn {
            border: none;
            background: unset;
            box-shadow: unset;
            text-align: center;
            font-weight: 300;
            padding-bottom: 10px;
            width: 110px;
            border-radius: unset;
            border-bottom: 2px solid white;
            
            &:hover, &.active {
                font-weight: bold;
                background: unset;
                box-shadow: unset;
                border-color: unset;
                border-bottom: 2px solid #333;
                transform: translateY(0);
            }

            &:hover, &:active, &:focus, &.active:focus {
                background: unset;
                box-shadow: unset;
                border-color: unset;
            }
        }
        hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .box-wrap {
            border: 1px solid #707070;
            padding: 10px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
            text-align: center;
        }
        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .select-wrap {
            text-align: left;
            position: relative;
            border: 1px solid #bbbabb;
            padding: 10px;
            padding-top: 15px;
            margin-top: 20px;
            & > p {
                position: absolute;
                top: -13px;
                left: 45px;
                background: #fbf9fa;
                padding: 0 7px;
            }
            .section-no {
                position: absolute;
                width: 24px;
                height: 24px;
                top: -10px;
                left: 10px;
                background: white;
                border-radius: 50%;
            }
            .section-no span {
                display: flex;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;
                background: #b085ad;
                border: 1px solid #bbbaba;
                border-radius: 50%;
                color: white;
                font-size: 12px;
                font-weight: bold;
                font-family: 'Arial';
            }
            break {
                flex-basis: 100%;
                width: 0px; 
                height: 0px; 
                overflow: hidden;
            }
            .left {
                float: left;
                text-align: left;
                width: 40%;
            }
            .right {
                float: left;
                width: 55%;
                text-align: left;
            }
            .sub-box-wrap {
                margin-top: 10px;
            }
            label {
                font-size: 12px;
                margin-left: 3px;
            }
            .clear { clear: both; }
        }
        .arrow-up-triangle {
            display: inline-block;
            margin: 0 auto;
            width: 18px;
            height: 15px;
            margin-top: 10px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 15px solid #714e6c;
        }
        .vega-embed,
        .vega-embed > div,
        .vega-embed > svg { text-align:left; }
    }
}
.maxContainer {
    width: 100%;
}
.rai-spinner {
    height: 1.5em;
    position: relative;
    width: 1.5em;
    margin: 0 auto;
}
.rai-spinner .rai-spinner-outer {
    border: 0.125em solid;
    opacity: .2;
}
.rai-spinner .rai-spinner-inner, .rai-spinner .rai-spinner-outer {
    border-radius: 100%;
    height: 100%;
    left: 0;
    margin-left: -0.0625em;
    margin-top: -0.0625em;
    position: absolute;
    top: 0;
    width: 100%;
}
.rai-spinner .rai-spinner-inner {
    -webkit-animation-duration: inherit;
    animation-duration: inherit;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: rai-spinner;
    animation-name: rai-spinner;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    border-bottom: 0.125em solid transparent!important;
    border-left: 0.125em solid transparent!important;
    border-radius: 100%;
    border-right: 0.125em solid transparent!important;
    border-top: 0.125em solid;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.rai-spinner .rai-spinner-inner, .rai-spinner .rai-spinner-outer {
    border-radius: 100%;
    height: 100%;
    left: 0;
    margin-left: -0.0625em;
    margin-top: -0.0625em;
    position: absolute;
    top: 0;
    width: 100%;
}
@keyframes rai-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(1turn);
    }
}
/* styles for options optimier - PDF Link end */

.custom-toggle {
    width: 42px;
    height: 19px;
}
.custom-toggle-slider {
    border: 3px solid #7b7b7b;
}
.custom-toggle-slider:before {
    height: 9px;
    width: 9px;
    left: unset;
    right: 2px;
    bottom: 2px;
    background: #7b7b7b;
}
.custom-toggle input:checked + .custom-toggle-slider {
    background: black;
    border: 3px solid black;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
    transform: translateX(-23px);
    background: #f8f8f8;
}
.datasource-wrap {
    margin-left: 30px;
    .drop-menu {
        border-bottom: 1px solid #DCDCDC;
    }
    .drop-menu a {
        display: inline-block;
        cursor: pointer;
        padding: 5px 0;
        margin-top: 15px;
        width: 60px;
        color: #172B4D;
        &:hover {
            color: #172B4D;
        }
        i {
            color: #714E6C;
            font-size: 13px;
            float: right;
            margin-top: 3px;
        }
        i.rotate {
            transform: rotate(90deg);
        }
    }
}
.edit-profile-item {
    .btn-remove {
        padding: 7px 28px;
    }
}
.logout-wrap {
    width: 100%;
    margin: 0 auto;
    display: flex;
    max-width: 310px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    button {
        padding: 6px 17px;
        font-size: 14px;
    }
    .btn-warning {
        background: #6a6c89;
        border: 1px solid #6a6c89;
    }
}
.support_mail {
    font-size: 15px;
    font-weight: bold;
    color: black;
    text-align: center;
}
.support_btn {
    margin-top: 30px;
    button {
        padding: 7px;
    }
}
.textarea-wrap {
    align-items: flex-start !important;
    .input-group-prepend {
        margin-top: 4px;
    }
    textarea {
        min-height: 200px;
    }
}

.landing__mobile {
    display: none;
}
.errorMessage_wrap {
    margin: 0;
    padding: 0;
    text-align: center;
}

.admin--submenu {
    li {
        width: 166px;
        text-align: center;
        background: #d3d2d2;
        margin-right: 10px !important;
        cursor: pointer;

        .nav-link {
            width: 100%;
            height: 100%;
            padding: 10px 0;
            color: #172B4D;
            &:hover {
                color: #172B4D;
            }
            &.active {
                background: #b4a3b2;
            }
        }
    }
}
.admin--content {
    margin-top: 30px;
    .add--member {
        background: #6363c1;
        border-radius: 8px;
        padding: 4px 15px;
        color: white;
        &:active, &:focus {
            background: #6363c1;
            color: white;
            opacity: 0.9;
        }
    }
    h3 {
        border-bottom: 1px solid #DCDCDC;
        margin-top: 20px;
    }
    .drop-menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .drop-menu a {
        width: 160px !important;
    }
    .datasource-wrap {
        width: 92%;
        .role--section {
            width: 20%;
            padding-left: 5%;
        }
        .auth--section {
            width: 35%;
        }
        .btn-wrap {
            width: 16%;
        }
    }
    .datasource-item {
        width: calc(92% - 20px) !important;
        margin-left: 50px;
        .edit-profile-item {
            justify-content: flex-start;
        }
        .title-section {
            width: 160px !important;
        }
        .enable-section {
            padding-left: 3%;
            width: 72% !important;
        }
        .credential-section {
            width: 26% !important;
        }
        .btn-info {
            width: 75px;
            padding: 4px 0;
        }
        .enable-option {
            width: auto !important;
        }
        .btn-section {
            text-align: left !important;
            width: 16% !important;
        }
        input {
            color: #333;
        }
    }
    .datasource-wrap .btn-info {
        width: 75px;
        padding: 4px 0;
    }
    .invited--status {
        width: 55%;
        padding-left: 5%;
        margin-top: 15px;
    }    
}
.user--role--select {
    position: relative;
    .input-group-prepend {
        position: absolute;
        left: 12px;
        z-index: 90;
        span {
            padding: 0;
        }
    }
    select {
        width: 100% !important;
        padding-left: 30px !important;
    }
}

.databuilder {
    margin-bottom: 30px;
    .reset--btn {
        border-top: 1px solid #DCDCDC;
        margin-bottom: 15px;
        padding-top: 20px;
        button {
            padding: 7px 15px;
        }
    }
    .selected_options {
        padding: 0 10px;
        margin-bottom: 5px;

        span {
            display: inline-block;
            padding: 2px 5px;
            background: #B18E9C;
            color: white;
            font-size: 13px;
            border-radius: 5px;
            margin-right: 5px;
        }
    }
    .product--list {
        border-top: 1px solid #DCDCDC;
        margin-bottom: 3px;
        padding-top: 20px;
    }
    .datasource-wrap {
        margin-left: 0;
    }
    .drop-menu a {
        width: 210px;
        i {
            float: left;
            margin-right: 10px;
            margin-left: 0;
        }
    }
    .custom-control-label {
        width: 100%;
        &::before, &::after {
            left: unset;
            right: 0;
        }
    }
    .evaluates-checkbox {
        display: inline-block !important;

        .custom-control-label {
            width: auto;
            &::before {
                border: 2px solid #5e72e4;
            }
            &::before, &::after {
                left: -1.35rem;
                right: unset;
                border-radius: 4px;
            }
        }
    }
    .input-group {
        border: 1px solid #714E6C;
        margin-top: 1rem;
    }
    .navbar-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            margin-right: 10px;
            font-size: 12px;
        }
        .custom-toggle {
            margin-top: 5px;
        }
    }
    .product-item {
        border-bottom: none !important;
        a {
            width: calc(100% - 90px);
            max-width: 550px;
            float: left;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 20px;
            margin-top: 2px;
            i {
                position: absolute;
                left: 0;
                top: 7px;
            }
        }
        button {
            padding: 2px 14px;
            float: left;
            margin-top: 4px;
        }
        strong.shorten-native-id {
            float: right;
            position: absolute;
            right: 0;
            background: white;
        }
    }
    .cart--item {
        margin-top: 10px;
        button, span {
            color: #714E6C;
            font-weight: bold;
            font-size: 1.4rem;
        }
    }
    .filter_wrap {
        height: calc(100vh - 315px);
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            min-height: 58vh;
            box-shadow: inset 0 0 6px rgba(113,78,108,0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(113,78,108,0.3); 
            border-radius: 3px;
        }
         
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            box-shadow: inset 0 0 6px rgba(113,78,108,1);
            -webkit-box-shadow: inset 0 0 6px rgba(113,78,108,1);
        }
    }
    .cart--wrap {
        height: 200px;
    }
}
.payment-success {
    background: #fbf9fa;
    height: calc(100vh - 270px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        font-size: 3rem;
        width: 280px;
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid #CCCCCC;
    }
    h3 {
        font-size: 2rem;
        font-weight: 100;
    }
    h4 {
        font-size: 1.5rem;
        font-weight: 100;
        text-align: center;

    }
    p {
        color: #32325d;
        text-align: center;
    }
    .runNewAnalysis {
        background: #6a6c89;
        border-color: #6a6c89;
    }
}
#tabs-icons-text {
    border-bottom: 1px solid #eceeef;
    justify-content: flex-start;
    .nav-item {
        width: 100px;
        padding-right: 0;
        flex: unset;
        margin-right: 8px;
        margin-left: 2px;
    }
    .nav-item a {
        background: #eceeef;
        width: 100px;
        color: black;
        box-shadow: unset;
        border-radius: unset;
        cursor: pointer;
        font-size: 1rem;
        &.selected {
            background: #b3a2b1;
        }
    }
}
.has-value-toggle {
    width: 52px;
    height: 24px;
    .custom-toggle-slider {
        border-color: #714E6C;
        background: #714E6C;
        color: white;
        font-size: 0.82rem;
        padding-left: 4px;
        text-align: left;
        line-height: 1.3;
    }
    .custom-toggle-slider:before {
        background: white;
        height: 17px;
        width: 17px;
        left: unset;
        right: 0px;
        bottom: 1px;
    }
    input:checked + .custom-toggle-slider {
        padding-left: 0;
        padding-right: 4px;
        text-align: right;
    }
    input:checked + .custom-toggle-slider:before {
        -webkit-transform: translateX(-29px);
        transform: translateX(-29px);
    }
}
.small-font {
    font-size: 11px;
}
.font-13 {
    font-size: 13px;
}
.hide-arrow::-webkit-outer-spin-button,
.hide-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.hide-arrow {
    -moz-appearance: textfield;
}
.portfolio-table {
    td {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.input-flex {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    label {
        margin-bottom: 0 !important;
    }
}
.max-width-70 {
    max-width: 70px;
    margin: 0 auto;
}
.max-width-40 {
    max-width: 40px;
    input {
        padding-left: 0.3rem !important;
        padding-right: 0.3rem !important;
    }
}
.little-bigger-font {
    font-size: 0.9rem !important;
}
.datamember {
    button {
        padding: 2px 10px;
        float: none;
    }
    .member_content {
        p, button { font-size:0.8rem; }
        h2 { font-size:1.1rem; span {font-weight:normal}}
        h3 { font-size:0.9rem; margin-top:1rem; span {font-weight:normal}}
        p { margin-bottom: 0.5rem;}
    }
    .family-item {
        flex: 50%;
        padding-right: 5px;
        margin-top: 10px;
    }
    .add-btn {
        padding: 6px 5px;
    }
    .remove-btn {
        padding: 6px 1px;
    }
    .input-group-alternative {
        box-shadow: unset;
        border: 1px solid #714E6C;
        border-radius: 5px;
        input {
            font-size: 0.75rem;
            height: auto;
        }
    }
    .indices-item {
        margin-bottom: 5px;
        input {
            border: 1px solid #714E6C;
            border-radius: 5px;
            display: inline-block;
            padding: 6px 5px;
            font-size: 0.75rem;
            height: auto;
            width: 90px;
            color: #222;
        }
    }
    .dataset-item {
        margin-bottom: 10px;
        p, button {
            margin: 0;
            clear: both;
        }
        button {
            float: right;
            margin-right: 6px;
        }
        button span {
            font-weight: bold;
            color: #714E6C;
        }
    }
    .custom-index-function {
        max-width: 40px;
    }
    .window-parameter {
        width: 72px;
        font-size: 0.75rem;
        border: 1px solid #714E6C;
        box-shadow: none;
        background-color: #e9ecef;
        opacity: 1;
        margin-left: 5px !important;
        display: inline-block;
        border-radius: 5px;
    }
    .select-box {
        position: relative;
        background: transparent;
        display: inline-block;

        select {
            border: 1px solid #714E6C;
            border-radius: 5px;
            cursor: pointer;
            min-width: 110px;
            appearance: none;
            padding: 6px 15px 6px 5px;
            font-size: 0.75rem;
            &::-ms-expand {
                display: none;
            }
        }
        .size-max {
            min-width: 110px;
        }
        .size-medium {
            min-width: 80px;
        }
        .size-min {
            min-width: 50px;
        }
        &::after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: 5px;
            top: 13px;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 6px solid #714E6C;
        }
    }
    .multi-select {
        /* width: 80px; */
        position: relative;

        &::after {
            display: none;
        }

        .selectBox {
            position: relative;
            z-index: 10;

            select {
                width: 100px;
            }

            &::after {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                right: 5px;
                top: 13px;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 6px solid #714E6C;
            }
        }

        .overSelect {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
        }

        .checkboxes {
            text-align: right;
            border: 1px solid #dadada;
            font-size: 0.75rem;
            padding: 3px;
            position: absolute;
            left: 0;
            width: 100%;
            background: white;
            z-index: 99;
            border-radius: 0 0 5px 5px;

            label {
                cursor: pointer;
                margin-bottom: 0.1rem;
                display: block;
            }

            input {
                float: right;
                margin-top: 2px;
                margin-left: 5px;
            }
        }
    }
    .fixed-switch {
        border: 1px solid #714E6C;
        border-radius: 5px;
        cursor: pointer;
        padding-left: 22px;
        padding-right: 7px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 8px;
            top: 6px;
            width: 8px;
            height: 20px;
            border: 1px solid black;
            border-radius: 5px;
        }
        &::after {
            content: '';
            position: absolute;
            left: 10px;
            top: 9px;
            width: 4px;
            height: 4px;
            background: black;
            border-radius: 2px;
        }
        &.fixed-on {
            &::before {
                background: black;
            }
            &::after {
                top: 19px;
                background: white;
            }
        }
        span {
            display: block;
            line-height: 0.8rem;
            font-size: 0.75rem;
        }
    }
    .snap-switch {
        width: 150px;
    }
    .snapday-wrap {
        position: relative;
        img {
            position: absolute;
            top: 7px;
            right: 7px;
            cursor: pointer;
        }
    }
    .calendar-wrap {
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 999;
    }
}
.custom-indice {
    margin-left: -30px;

    .custom-checkbox {
        width: 30px;
    }
    .custom-control-label {
        width: 30px;
        &::before, &::after {
            left: 0;
            right: unset;
        }
        &::before {
            border: 2px solid #5e72e4;
        }
    }
    .custom-control {
        padding-left: 0;
    }
    .form-control {
        min-width: 300px;
        max-width: 330px;
        padding: 7px 15px;
    }
}
.custom-indices-value {
    padding: 7px !important;
    color: black;
}
.custom-indices-alias {
    min-width: auto !important;
    max-width: 150px !important;
    width: 100px !important;
}
.operation-btn {
    border-radius: 50%;
    padding: 2px 8px !important;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

.datatype {
    padding-top: 3rem;
    text-align: center;

    h2 {
        font-size: 1.6rem;
        color: #172B4D;
        margin-bottom: 0;
    }
    h4 {
        font-size: 1.4rem;
        color: #172B4D;
        margin-bottom: 1rem;
    }
    p {
        font-size: 1rem;
        color: #172B4D;
        line-height: 1.4;
    }
    p.h-desc {
        font-size: 1.2rem;
        margin-top: 0;
    }
    .datatype-item {
        width: 70%;
        margin: 1rem auto 3rem auto;
        padding: 2rem 0;
        padding-bottom: 70px;
        box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
        background: white;
        border: 1px solid #BEBEBE;
        position: relative;
    }
    .datatype-item img {
        position: absolute;
        left: calc(50% - 45px);
        bottom: -50px;
    }
    button {
        padding: 9px 15px;
        margin-top: 1rem;
    }
}
.snap-description {
    width: 400px;
    margin: 0 auto;
    padding: 0 1.5rem;
    text-align: center;
    padding-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    background: white;
    border: 1px solid #BEBEBE;
    position: relative;

    p {
        margin-bottom: 0.8rem;
    }
    ul {
        display: inline-block;
        text-align: left;
    }
    li {
        font-size: 12px;
    }
}
.snap-item {
    margin-bottom: 1rem;

    label {
        text-align: left;
        width: 170px;
        margin-bottom: 0;
        line-height: 1.2;

        span {
            font-size: 0.55rem;
        }
    }
    .w-150 {
        width: 150px;
    }
    .w-60 {
        width: 65px;
    }
    input {
        color: #000;
        padding: 0.45rem 0.75rem;
        text-align: center;
    }
    select {
        width: 150px;
        text-align-last: center;
    }
    button {
        padding: 7px;
    }
    .new-relative-btn {
        width: 100%;
        text-align: center;
        font-size: 12px;
        padding: 10px 0;
        margin-top: 10px;
    }
}

.dataFamilyMemberSection {
    height: calc(100vh - 315px);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        min-height: 58vh;
        box-shadow: inset 0 0 6px rgba(113,78,108,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(113,78,108,0.3); 
        border-radius: 3px;
    }
        
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(113,78,108,1);
        -webkit-box-shadow: inset 0 0 6px rgba(113,78,108,1);
    }
}
.privacy-wrap {
    box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    background: white;
    border: 1px solid #BEBEBE;
    margin-bottom: 1rem;
    width: 90% !important;
}
.desc-card {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    text-align: center;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.3) 6px 6px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    background: white;
    border: 1px solid #BEBEBE;

    h6 {
        font-size: 1.1rem;
        font-weight: normal;
    }
    p, li {
        font-size: 14px;
        margin-bottom: 0;
    }
    p {
        margin-bottom: 8px;
    }

    & > div {
        margin: 1rem auto;
    }
}
.font-15 { 
    p {
        font-size: 15px !important; 
    }
}

.landing-header {
    height: 100vh;
    background: #f9f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
        text-align: center;
        font-size: 3rem;
        color: #8A8A8A;
        margin: 0;
        padding: 0;
        width: 50%;
        min-width: 300px;
        transition: transform 2s ease-in-out;
        transform: translateX(+100vw);
        &:first-child {
            border-bottom: 1px solid #707070;
            transform: translateX(-100vw);
        }
    }
    .btn-info {
        width: 200px;
        background: white;
        border: 1px solid #6a6c89;
        border-radius: 20px;
        color: #6a6c89;
        height: 37px;
        opacity: 0;
        transition: opacity 2s ease-in-out;
    }
    .btn-warning {
        width: 200px;
        background: #6a6c89;
        border: 1px solid #6a6c89;
        border-radius: 20px;
        color: white;
        height: 37px;
        opacity: 0;
        line-height: 1;
        transition: opacity 4s ease-in-out;
    }
}
.landing-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    .sub-item {
        width: 50%;
        text-align: center;
        h2 {
            color: #8A8A8A;
            font-size: 2.8rem;
            font-weight: normal;
            border-bottom: 1px solid #707070;
            display: inline-block;
            padding: 0 10px;
        }
        p {
            color: #6A6C89;
            transition: all 2s ease-in-out;
            overflow: hidden;
            opacity: 0;
            height: 0;
        }
        p.show {
            transition: all 2s ease-in-out;
            opacity: 1;
            height: auto
        }
    }
}
.landing-footer {
    height: 480px;
    background: #f9f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* custom slider style */
.datamember {
    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
        background-color: #714E6C !important;
    }
    .noUi-connect {
        background: #714E6C !important;
    }
    .noUi-horizontal .noUi-handle.noUi-active, .noUi-vertical .noUi-handle.noUi-active {
        box-shadow: 0px 0px 0px 2px #714E6C !important;
    }
    .noUi-connects {
        overflow: visible !important;
    }
    .noUi-connects::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 25px;
        background: #714E6C;
        left: 50%;
        top: -10px;
        z-index: 99;
    }
    .noUi-connects::after {
        content: 'current level';
        font-size: 11px;
        position: absolute;
        width: 100px;
        text-align: center;
        height: 20px;
        left: calc(50% - 50px);
        top: 20px;
        z-index: 99;
    }
    .noUi-horizontal .noUi-tooltip {
        bottom: -23px !important;
        background: #707070;
        border-radius: 5px;
        color: white;
        font-size: 10px;
        padding: 1px 5px !important;
    }
}
.overflow-hidden { overflow: hidden; }
.width-75 { width: 75%; }
.rdt.rdtOpen .rdtPicker { right: 0; }

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.loading-modal {
    .modal-content {
        background: transparent;
        border: none;
        box-shadow: none;
        text-align: center;
    }
    img {
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
    }
}

.datalib-wrap {
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 10px;
    padding-top: 7px;
    .product-item a {
        font-weight: bold;
    }
    .filter_wrap {
        overflow-x: hidden;
    }
}

.homepage-wrap {
    .desc-card h6 {
        font-size: 1.2rem;
    }
    .desc-card p {
        font-size: 13px;
    }
}
.collapse-btn {
    display: inline-block;
}
.table-content {
    max-height: 300px;
    margin-bottom: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        min-height: 58vh;
        box-shadow: inset 0 0 6px rgba(113,78,108,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(113,78,108,0.3); 
        border-radius: 3px;
    }
     
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(113,78,108,1);
        -webkit-box-shadow: inset 0 0 6px rgba(113,78,108,1);
    }
}
.scenario-container {
    .scenario-item-wrap {
        background: white;
        border-radius: 6px;
        box-shadow: 3px 4px 10px rgba(0,0,0,0.18);
        
        td { text-align: center; }
        th { text-align: center; }
    }
    .portfolio-wrap {
        height: 100%;
        align-content: flex-start;
    }
    .datamember .select-box select {
        padding: 2px 15px 2px 5px;
    }
    .datamember .select-box::after {
        top: 8px;
    }
    .datamember .input-group-alternative input {
        padding: 0.2rem 0.75rem;
    }
    table .custom-toggle {
        margin: 3px auto;
    }
    p, input { 
        color:#32325d; 
        font-weight: 500;
    }
    .custom-chart-section {
        background: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        width: 95%;
        margin: 20px auto;
        margin-bottom: 30px;
        min-height: 250px;
    }
    .runScenarioBtn {
        position: absolute;
        bottom: 40px;
        font-size: 0.875rem;
        padding: 4px 10px;
    }
}

@media (min-width: 768px) {
    .col-xl-4 {
        -webkit-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .home-wrap {
        width: 100%;
    }
    .homepage-wrap {
        width: 100%;
    }
    .authextra {
        display: none;
    }
    .auth_wrap {
        width: 98%;
    }
    .signin--wrap {
        width: 100%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        max-width: 50%;
    }
    .onboarding--wrap {
        font-family: 'Open Sans';
        color: black;
        .form-group {
            width: 95%;
        }
        .form-control {
            height: 30px;
        }
    }
    .input-group {
        display: flex;
        align-items: center;
    }
    .modal {
        .credentialModal {
            max-width: 518px;
            width: 71%;
        }
        .companyModal {
            max-width: unset;
            width: 90%;
        }
        .errModal {
            max-width: unset;
            width: 60%;
        }
        .wrap {
            display: flex;
        }
        .item-list {
            border-right: 1px solid #CCCCCC;
            .btn-select {
                padding-left: 20px;
            }
        }
        li {
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .d-xl-none {
        display: none !important;
    }
    .instruction-block {
        padding-right: 10px;
    }

    .profile-wrap {
        .col-md-10 {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1200px;
    }
    .authextra {
        display: flex;
    }
    .auth_wrap {
        width: 50%;
    }
    .signin--wrap {
        width: 100%;
    }
    .modal {
        .companyModal {
            max-width: unset;
            width: 80%;
        }
        .credentialModal {
            max-width: 518px;
            width: 40%;
        }
        .errModal {
            max-width: unset;
            width: 40%;
        }
        .wrap {
            display: flex;
        }
    }
    .profile-wrap {
        .col-md-10 {
            max-width: 84%;
            flex: 0 0 84%;
        }
    }
}

@media (min-width: 1600px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1200px;
    }
    .home-wrap {
        width: 90%;
    }
    .homepage-wrap {
        width: 94%;
    }
    .onboarding--wrap {
        .form-group {
            width: 80%;
        }
    }
    .modal {
        .companyModal {
            max-width: 80%;
        }
        .credentialModal {
            max-width: 518px;
        }
    }
}

@media (min-width: 1630px) {
    .onboarding--wrap {
        margin-top: 1%;
        .form-control {
            height: 40px;
        }
    }
    .modal {
        .companyModal {
            max-width: 1200px;
        }
        .credentialModal {
            max-width: 518px;
        }
    }
}

@media (min-width: 1800px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1400px;
    }
}

@media only screen and (max-width: 1400px) {
    .about--title {
        font-size: 23px;
    }
    .header {
        .landing--section {
            h6 {
                font-size: 23px;
            }
        }
    }
    .profile-wrap {
        h2, h3 {
            font-size: 23px;
        }
    }
    p {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .home-wrap {
        .col-xl-4 {
            p {
                width: 95%;
            }
        }
    }
    .datamember .col-xl-4 {
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .min-height300 {
        .col-xl-4 {
            min-height: 305px;
        }
        p {
            width: 95% !important;
        }
    }
    .min-height330 {
        .col-xl-4 {
            min-height: 360px;
        }
        p {
            width: 95% !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    #navbar-main #navbar-collapse {
        display: none;
    }
    .landing__graph {
        display: none;
    }
    .landing__mobile {
        display: block;
    }
    .landing--section .card {
        margin-top: 50px;
    }
    .landing--section .landing__graph img {
        float: none;
        width: 100%;
    }
    .home-wrap .col-xl-4 {
        border: none;
    }
    .home-wrap .nav-link {
        position: static;
    }
    .d-xl-none {
        display: list-item !important;
    }
    .code--extra, .register--extra {
        display: none;
    }
    .home-wrap {
        .mt-7:last-child {
            margin-top: 0 !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    .auth_wrap {
        width: 98%;
        margin-bottom: 20px;
    }
    .modal {
        .companyModal, .errModal {
            max-width: unset;
            width: 96%;
        }
        .credentialModal {
            width: 96%;
        }
        .wrap {
            display: block;
        }
        .item-list {
            border-right: none;
            .btn-select {
                padding-left: 0;
            }
        }
        li {
            &:last-child {
                border-bottom: 1px solid #CCCCCC;
            }
        }
    }
    .border--r {
        border: none;
    }
}
.custom-control-label {
    &::before, &::after {
        border: 2px solid #5e72e4;
    }
}
input[type=range].input-range-blue,
input[type=range].input-range-red {
    -webkit-appearance: none;
}
input[type=range].input-range-blue::-webkit-slider-runnable-track {
    height: 7px;
    background-color: #1B7001;
    -webkit-appearance: none;
    border-radius: 5px;
}
input[type=range].input-range-blue::-webkit-slider-thumb {
    height: 15px;
    width: 15px;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #0c3300;
    border: 1px solid #0c3300;
    background: #1B7001;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}
input[type=range].input-range-red::-webkit-slider-runnable-track {
    height: 7px;
    background-color: #F20D2A;
    -webkit-appearance: none;
    border-radius: 5px;
}
input[type=range].input-range-red::-webkit-slider-thumb {
    height: 15px;
    width: 15px;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #49020c;
    border: 1px solid #49020c;
    background: #F20D2A;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}
  